import React from "react"
import { gsap } from "gsap"


let timerId;
let cuePoints, currentCuePoint;

//const FullscreenVideo = ({ file, autoPlay }) => {
class FullscreenVideo extends React.Component {

    seekVideo = (seconds) => {
        console.log("video is at: " + this.refs.videoRef.currentTime + ", seek to " + seconds);

        this.refs.videoRef.currentTime = seconds;
        this.refs.videoRef.play();
    }

    hideVideo = (time = 0.2) => {
        console.log('hideVideo');
        gsap.to(this.refs.videoRef, time, { autoAlpha: 0 });
    }

    showVideo = (time = 0.2) => {
        console.log('showVideo');
        this.refs.videoRef.classList.remove('hide');
        gsap.to(this.refs.videoRef, time, { autoAlpha: 1 });
    }

    playVideo = (time = null) => {
        console.log('FSV: playVideo');
        if(time === 0){
            this.refs.videoRef.currentTime = 0;
        }
        this.refs.videoRef.play();
        
        if(this.props.cuePoints) {
            timerId = setInterval(() => {
                this.checkCurrentTime();
            }, 100);
        }
    }

    pauseVideo = () => {
        this.refs.videoRef.pause();
        console.log("Pause Video");
    }
    videoEnded = () => {
        console.log(
            'VIDEO ENDED'
        )
        
        if (this.props.ended) {
            this.props.ended();
        }
        if(this.props.loop){
            this.refs.videoRef.play();
        } else {
            clearInterval(timerId);
        }
    }

    muteVideo = () => {
        this.refs.videoRef.muted = true;
    }

    componentDidMount() {
        this.refs.videoRef.addEventListener("ended", this.videoEnded);
        this.refs.videoRef.disablePictureInPicture = true;
        // subscribe state change
        console.log(this.props.globalState);
        
        try{
            if(this.props.globalState.baseAudioRef.isMuted()) {
                this.muteVideo();
            }
        } catch(e){
            console.warn(e);
        }

        
        clearInterval(timerId);
        cuePoints = [];
        currentCuePoint = 0;

        if(this.props.cuePoints) {
            cuePoints = this.props.cuePoints;
        }
        
        //Hls is added in gatsby-ssr.js
        /* eslint-disable */
        let videoStream = this.props.file;
        var video = this.refs.videoRef;

        console.log("videoStream: " + videoStream);
        console.log("Hls.isSupported: " + Hls.isSupported());
        if (Hls.isSupported()) {
            
            var hls = new Hls();
            // bind them together
            hls.attachMedia(video);
            // MEDIA_ATTACHED event is fired by hls object once MediaSource is ready
            hls.on(Hls.Events.MEDIA_ATTACHED, function () {
                console.log("video and hls.js are now bound together !");
                hls.loadSource(videoStream);
                hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
                    console.log("manifest loaded, found " + data.levels.length + " quality level");
                });
            });
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = videoStream; //'https://video-dev.github.io/streams/x36xhzz/x36xhzz.m3u8';
            console.log("Set html5 video src to " + videoStream);
            video.addEventListener('canplay',function() {
                console.log('video can play');
              video.play();
            });
        }
        

        /*eslint-enable */

    }

    checkCurrentTime = () =>{
        if(!this.refs.videoRef) {
            clearInterval(timerId);
        } else {
            let t = this.refs.videoRef.currentTime
            //console.log(t);
            for(var i=0; i<cuePoints.length; i++){
                if(t > cuePoints[i]){
                    currentCuePoint = i+1;
                }
            }
            this.props.cuePointCallback(currentCuePoint);
        }
    }

    handleStateChange(state, prevState) {
        // copy player state to this component's state
        this.setState({
            player: state,
            currentTime: state.currentTime
        });
    }

    render() {
        
        return (
            //console.log('video file: ' + this.props.file),
            /*<video ref="videoRef" poster={this.props.poster} autoPlay={this.props.autoPlay} loop={this.props.loop} muted={this.props.muted} playsInline className="fullscreen-video">
                <source src={this.props.file} type="video/mp4" />
                <track kind="captions" srcLang="en" />
            </video>*/
            <video ref="videoRef" poster={this.props.poster} autoPlay={this.props.autoPlay} loop={false} muted={this.props.muted} playsInline className={this.props.fullscreenClass} transparent={0}>
                <track kind="captions" srcLang="en" />
            </video>
        )
    }
}

export default FullscreenVideo
