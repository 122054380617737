import React from "react"
import PropTypes from "prop-types"
import { gsap } from "gsap"
import Next from "./next"
import Prev from "./prev"


const ANI_SPEED = 0.5;
const DIST_OUT = 4;

//const Next = ({ location }) => (
class PrevNext extends React.Component {
    
    /**
     * props passed to PrevNext
     * 
     * globalState 
     * nextLocation
     * nextHijack 
     * nextCallback
     * prevLocation
     * prevHijack
     * prevCallback
     * isTransitioning
     * directLink
     * */
      
    state = {
        nextHijack: false,
        prevHijack: false,
        directLink: null
    }
    
    

    //Directly link to the next page.
    goNext = (page) => {
        console.log("prevnext.js goNext: " + page);
        this.hideNextBtn();
        this.hidePrevBtn();
        this.setState({directLink: page});
    }

    handleNextClick = () => {
        
        console.log("handleNextClick");
        
        //event.preventDefault();
        this.hideNextBtn();
        this.hidePrevBtn();
        if(this.state.nextHijack){
            this.props.nextCallback();
        } 
    }

    handlePrevClick = () => {
        
        console.log("handlePrevClick");
        
        //event.preventDefault();
        this.hideNextBtn();
        this.hidePrevBtn();
        if(this.state.prevHijack){
            this.props.prevCallback();
        } 
    }

    showNextBtn = (delay) => {
        console.log("Show next button");
        gsap.to(this.refs.nextBtnWrapperRef, ANI_SPEED, { x: - (40 - DIST_OUT), autoAlpha: 1, delay: delay });
    }

    showPrevBtn = (delay) => {
        console.log("Show prev button");
        gsap.to(this.refs.prevBtnWrapperRef, ANI_SPEED, { x: (31 - DIST_OUT), autoAlpha: 1, delay: delay });
    }

    showPrevNextBtns = (delay) => {
        this.showNextBtn(delay);
        this.showPrevBtn(delay);
    }

    hideNextBtn = () => {
        gsap.to(this.refs.nextBtnWrapperRef, 0, { x: 0, autoAlpha: 0 });
        console.log("Hide next button");
    }
    hidePrevBtn = () => {
        gsap.to(this.refs.prevBtnWrapperRef, 0, { x: -26, autoAlpha: 0});
        console.log("Hide prev button");
    }

    componentDidMount = () => {
        console.log("Hijack Next: " + this.props.nextHijack);
        console.log("Hijack Prev: " + this.props.prevHijack);
        this.updateNextHijacking(this.props.nextHijack);
        this.updatePrevHijacking(this.props.prevHijack);

        /*
        if (!this.props.nextHijack) {
            this.showNextBtn(3);
        }
        if (!this.props.prevHijack) {
            this.showPrevBtn(3);
        }
        */
    }

    updateNextHijacking = (bool) => {
        console.log('update next hijacking: ' + bool);
        let nextHijack = { ...this.state.nextHijack };
        nextHijack = bool;
        this.setState({ nextHijack });
    }

    updatePrevHijacking = (bool) => {
        console.log('update prev hijacking: ' + bool);
        let prevHijack = { ...this.state.prevHijack };
        prevHijack = bool;
        this.setState({ prevHijack });
    }

    render() {
        const displayNext = this.props.nextLocation === "" && !this.state.nextHijack ? {display: "none"} : {display: "block"};
        const displayPrev = this.props.prevLocation === "" && !this.state.prevHijack ? {display: "none"} : {display: "block"};
        return (
            <>

            <div className="next-btn-wrapper" ref="nextBtnWrapperRef" style={ displayNext }>
                <Next viewsWrapper={this.props.globalState.transitionRef} location={ this.props.nextLocation } hijacking={ this.state.nextHijack } callback={ this.handleNextClick } isTransitioning={this.props.isTransitioning} transitionDuration={ this.props.globalState.transitionDuration } directLink={this.state.directLink} transitionDirection={ this.props.globalState.updateTransitionDirection}/>
            </div>
            
            <div className="prev-btn-wrapper" ref="prevBtnWrapperRef" style={ displayPrev }>
                <Prev viewsWrapper={this.props.globalState.transitionRef} location={ this.props.prevLocation } hijacking={ this.state.prevHijack } callback={ this.handlePrevClick } isTransitioning={this.props.isTransitioning} transitionDuration={ this.props.globalState.transitionDuration } transitionDirection={ this.props.globalState.updateTransitionDirection} />
            </div>
            </>
        )
    }



}

PrevNext.propTypes = {
    nextLocation: PropTypes.string,
    prevLocation: PropTypes.string,
}

PrevNext.defaultProps = {
    nextLocation: ``,
    prevLocation: ``,
}



export default PrevNext
