/** Remains: Computer video **/

import React from "react";
import { gsap } from "gsap"
import FullscreenVideo from "../../components/media/fullscreen-video"
import { preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"


let globalState, data, preloadData;

class RemainsPage3 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[3];
    preloadData = globalState.assets[4];

    //refs
    this.bgVideoRef = this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
  }


  componentDidMount = () => {

    setTimeout(() => {
      this.bgVideoRef.playVideo();
    }, globalState.transitionDuration);

    //position text
    positionText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);


    //show text
    gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0 });

    //add base track
    //globalState.baseAudioRef.updateTrack(1, false);

    //set volume for base track
    globalState.baseAudioRef.updateVolume(2, 100);
    
    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.text1,
      globalState.cdnUrl + preloadData.bgVideoPoster,
    ], this.preloadCallback);

  }

  preloadCallback = () => {
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }



  componentWillUnmount() {
    this.bgVideoRef.pauseVideo();

    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);

  }

  bgVideoEnded = () => {
    console.log("Bg Video ended");
      //this.bgVideoRef.seekVideo(30);
  }

  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({ textVisible: !this.state.textVisible });

    //reset the animation delay
    resetAnimationDelay();

    if (this.state.textVisible) {
      //show text
      showText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop, 0);

    } else {
      //hide text
      hideText(this.refs.text1Ref, "right");
    }
  }

  isTransitioning = () => {
    this.bgVideoRef.pauseVideo();
  }

  

  render() {

    return (

      <>

        <link rel="prefetch" href="/remains/4" />
        {preloadData && (
        <link rel="preload" as="video" href={globalState.videoUrl + preloadData.bgVideo} />
        )}

        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/remains/4" prevLocation="/remains/2"
          isTransitioning={this.isTransitioning} />
        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Remains" />

          <div className="text-wrapper">
            <div className="text text--right-top" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginRight: "1vw", marginTop: "30vh" }} onClick={this.toggleTextDisplay} alt="What amazed most folds is that the town’s least likely resident stepped up to be its caretaker." />
            </div>
          </div>

          
          {data.bgVideo ?
            <FullscreenVideo file={globalState.videoUrl + data.bgVideo}
              poster={globalState.cdnUrl + data.bgVideoPoster} fullscreenClass={'fullscreen-video'} autoPlay={false} loop={true} muted={false} ended={this.bgVideoEnded} ref={div => this.bgVideoRef = div} globalState={globalState} /> : <div />
          }


        </div>
        )}
      </>
    );
  }


};

export default RemainsPage3
