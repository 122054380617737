import React from "react";
import { useTransitionStore } from "gatsby-plugin-transitions"
//import { config } from "react-spring"
//import * as easings from 'd3-ease'



const Prev = ({viewsWrapper, location, hijacking, callback, isTransitioning, transitionDirection}) => {
    const [, dispatch] = useTransitionStore();
    
    const handleClick = () => {

        if(callback){
            callback();
        }
        
        //recalc enter and exit values
        let transformEnter = "translate3d(-100vw,0,0)";
        let transformLeave = "translate3d(100vw,0,0)";

        
        if(!hijacking) {
            dispatch({
                type: "NAVIGATE",
                to: location,
                enter: {
                    transform: transformEnter,
                    config: { delay: 100, mass: 1, tension: 170, friction: 26, velocity: 0 },
                    //config: { mass: 1, tension: 120, friction: 14, clamp: true, duration: transitionDuration, easing: easings.easeQuadInOut },
                    onRest: () => {
                        viewsWrapper.classList.remove("is-transitioning", "prev");
                    }
                },
                usual: {
                    transform: "translate3d(0vw,0,0)"
                },
                leave: {
                    transform: transformLeave,
                    config: { delay: 100, mass: 1, tension: 170, friction: 26, velocity: 0 },
                    //config: { mass: 1, tension: 120, friction: 14, clamp: true, duration: transitionDuration+35, easing: easings.easeQuadInOut },
                    onStart: () => {
                        viewsWrapper.classList.add("is-transitioning", "prev");
                        transitionDirection("prev");
                        if (isTransitioning) {
                            isTransitioning();
                        }
                    }
                },
                mode: "immediate"
            });
        }
    };




    return (
        <button onClick={ handleClick } alt="Prev" className="prev-btn"></button>
    );
};

export default Prev;