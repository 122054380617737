import React, { useCallback } from "react";
import { useTransitionStore } from "gatsby-plugin-transitions"
//import * as easings from 'd3-ease'
//import { config } from 'react-spring'


const Next = ({ viewsWrapper, location, transition, hijacking, callback, isTransitioning, transitionDirection, directLink='', updateTransitionDirection }) => {
    const [, dispatch] = useTransitionStore();

    const handleClick = () => {

        if (callback) {
            callback();
        }
        
        //if no hijacking go ahead and navigate
        if (!hijacking) {

            goNext(location);
        }
    };

    const goNext = useCallback((location) => {

        console.log("Next.js goNext called to: " + location);
        
        //recalc exter and exit values
        let transformEnter = "translate3d(100vw,0,0)";
        let transformLeave = "translate3d(-100vw,0,0)";
        let opacityEnter = 1;

        if (transition === "fade") {
            opacityEnter = 0;
            transformEnter = "translate3d(0vw,0,0)";
            transformLeave = "translate3d(0vw,0,0)";
        }
        /*
        try{
            //let s = new Audio("/audio/global/one-second-of-silence.mp3");
            let s = new Audio(globalState.silence);
            s.play();
        } catch (e) {
            console.log(e);
        }
        */
        dispatch({
            type: "NAVIGATE",
            to: location,
            enter: {
                transform: transformEnter,
                opacity: opacityEnter,
                config: { delay: 100, mass: 1, tension: 170, friction: 26, velocity: 0 },
                //config: { easing: Quad.easeOut, duration: transitionDuration },
                //config: 'wobbly', //{ mass: 1, tension: 120, friction: 14, clamp: true, duration: transitionDuration, easing: easings.easeQuadInOut },
                onRest: () => {
                    //  console.log("New page has transitioned in via next!");
                    //this.showNextBtn(3);
                    viewsWrapper.classList.remove("is-transitioning", "next");
                }
            },
            usual: {
                transform: "translate3d(0vw,0,0)",
                opacity: 1
            },
            leave: {
                transform: transformLeave,
                opacity: 1,
                config: { delay: 100, mass: 1, tension: 170, friction: 26, velocity: 0 },
                //config: { easing: Quad.easeOut, duration: transitionDuration },
                //config: 'wobbly', //{ mass: 1, tension: 120, friction: 14, clamp: true, duration: transitionDuration + 35, easing: easings.easeQuadInOut },
                onStart: () => {
                    //  console.log('leaving next');
                    viewsWrapper.classList.add("is-transitioning", "next");
                    transitionDirection("next");
                    if (isTransitioning) {
                        isTransitioning();
                    }
                }
            },
            mode: "immediate"
        });
    }, [dispatch, isTransitioning, transition, viewsWrapper, transitionDirection]);

    //similar to componentDidMount
    React.useEffect(() => {
        console.log("directLink: " + directLink);
        if(directLink){
            goNext(directLink);
        }
    }, [directLink, goNext]);
    

    return (
        <button onClick={handleClick} alt="Next" className="next-btn"></button>
    );
};

export default Next;